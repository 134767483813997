import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import Loader from 'react-loaders';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLoginMutation } from '../../api/authApiSlice';
import { setLoginCredentials, setToken, setUser } from '../../api/authSlice';
import {
  Chip,
  IconButton,
  InputAdornment,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const LoginPage = () => {
  const userRef = useRef();
  const errRef = useRef();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [login, { isLoading }] = useLoginMutation();
  const dispatch = useDispatch();

  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    setErrMsg('');
  }, [email, password]);
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await login({ email, password }).unwrap();

      localStorage.setItem('access', response.tokens.access.token);
      localStorage.setItem('refresh', response.tokens.refresh.token);
      dispatch(
        setToken({
          accessToken: response.tokens.access,
          refreshToken: response.tokens.refresh,
        })
      );
      dispatch(setUser({ user: response.user }));
      setEmail('');
      setPassword('');
      if (response.user.role === 'admin') navigate('/AdminPage');
      if (response.user.role === 'user') navigate('/MainPage');
    } catch (err) {
      if (!err?.status) {
        // isLoading: true until timeout occurs
        setErrMsg('Server cevap vermiyor');
      } else if (err.status === 400) {
        setErrMsg('E-Mail veya Şifre alanı boş olamaz.');
      } else if (err.status === 401) {
        setErrMsg('Girdiğiniz E-Mail veya Şifre yanlış.');
      } else {
        setErrMsg('Giriş başarısız');
      }
    }
  };

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleEmailInput = (e) => setEmail(e.target.value);
  const handlePwdInput = (e) => setPassword(e.target.value);

  const forgotPassword = () =>{

    navigate("/forgot-password")

  }

  const content = isLoading ? (
    <Loader type='pacman' />
  ) : (
    <ThemeProvider theme={darkTheme}>
      <Container component='main' maxWidth='xs'>
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component='h1' variant='h5'>
            Sipariş Takip Giriş
          </Typography>
          <Box
            component='form'
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin='normal'
              ref={userRef}
              value={email}
              onChange={handleEmailInput}
              required
              fullWidth
              id='email'
              label='Email'
              name='email'
              autoComplete='email'
              autoFocus
            />
            <TextField
              margin='normal'
              ref={userRef}
              value={password}
              onChange={handlePwdInput}
              required
              fullWidth
              name='password'
              label='Şifre'
              type={showPassword ? 'text' : 'password'}
              id='password'
              autoComplete='current-password'
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge='end'
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <Button
              type='submit'
              fullWidth
              variant='contained'
              sx={{ mt: 3, mb: 2 }}
            >
              Giriş Yap
            </Button>
            <Button
              variant='outlined'
              fullWidth
              onClick={forgotPassword}
              color='info'
              sx={{ minHeight: 40 }}
            >Şifremi Unuttum</Button>
          </Box>
          <br></br>
          <Typography align='center'>
            The term 'Etsy' is a trademark of Etsy, Inc. This application uses
            the Etsy API but is not endorsed or certified by Etsy, Inc.
          </Typography>
        </Box>

        {errMsg !== '' ? (
          <Alert variant='outlined' severity='error'>
            {errMsg}
          </Alert>
        ) : (
          <div></div>
        )}
      </Container>
    </ThemeProvider>
  );

  return content;
};

export default LoginPage;
