import { ThemeProvider } from '@emotion/react';
import {
  Box,
  Button,
  createTheme,
  CssBaseline,
  TextField,
  Typography,
} from '@mui/material';
import api from '../../middleware/backendApi.ts';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const ForgotPasswordPage = () => {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    api.forgotPassword(email).then().catch();
    
  };

  const handleEmailInput = (e) => setEmail(e.target.value);

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline>
        <Box
          sx={{
            marginTop: 15,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography>
            Lütfen sistemimizde kayıtlı olan E-Mail adresinizi giriniz
          </Typography>
          <Box
            component='form'
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin='normal'
              value={email}
              onChange={handleEmailInput}
              required
              fullWidth
              id='email'
              label='Email'
              name='email'
              autoComplete='email'
              autoFocus
            />

            <Button
              type='submit'
              fullWidth
              variant='contained'
              sx={{ mt: 3, mb: 2 }}
            >
              Gönder
            </Button>
          </Box>
        </Box>
      </CssBaseline>
    </ThemeProvider>
  );
};

export default ForgotPasswordPage;
