import {
  Box,
  createTheme,
  CssBaseline,
  ThemeProvider,
  Typography,
} from '@mui/material';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { HashLoader } from 'react-spinners';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const CallbackPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate('/');
    }, 2000);
  }, []);

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline>
        <Box
          sx={{
            marginTop: 15,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography>
            Yetkilendirme başarılı. Şimdi ana sayfaya yönlendiriliyorsunuz.
          </Typography>
          <br />

          <HashLoader color='#368ed6' />
        </Box>
      </CssBaseline>
    </ThemeProvider>
  );
};

export default CallbackPage;
