import { apiSlice } from "./apiSlice";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    login: builder.mutation({
      query: loginCredentials => ({
        url: '/auth/login',
        method: 'POST',
        body: { ...loginCredentials }
      })
    }),
    logout: builder.mutation({
      query: logoutCredentials => ({
        url: '/auth/logout',
        method:'POST',
        body: {...logoutCredentials}
      })
    }),
    resetPassword: builder.mutation({
      query : forgotPasswordCredentials => ({
        url:'/auth/forgot-password',
        method: 'POST',
        body: {...forgotPasswordCredentials}
      })
    }),
    forgotPassword: builder.mutation({
      query : forgotPasswordCredentials => ({
        url:'/auth/forgot-password',
        method: 'POST',
        body: {...forgotPasswordCredentials}
      })
    }),
    // getUSers: builder.query({
    //   query: getUsersAction => ({
    //     url: 'jgf',
    //
    //   })
    // })
  })
})

export const {
  useLoginMutation
} = authApiSlice
