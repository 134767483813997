import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import TablePagination from '@mui/material/TablePagination';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

import { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectAccessToken, selectCurrentUser } from '../../api/authSlice';
import api from '../../middleware/backendApi.ts';
import { Button, Modal, Typography } from '@mui/material';
import { Box } from '@mui/system';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const ProductDataTable = (props) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [modalImageUrl, setModalImageUrl] = useState('');
  const [open, setOpen] = useState(false);
  const handleOpen = (tempModalImageUrl) => {
    setModalImageUrl(tempModalImageUrl)
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const tokenObject = useSelector(selectAccessToken);
  const user = useSelector(selectCurrentUser);

  function selectImages(productData) {
    let imagesArray = [];

    for (let i = 0; i < props.shopListing.length; i++) {
      for (let j = 0; j < productData.transactions.length; j++) {
        if (
          productData.transactions[j].listing_id ===
          props.shopListing[i].listing_id
        ) {
          imagesArray.push(props.shopListing[i].images[0].url_570xN);
        }
      }
    }
    let uniqueImageUrls = [...new Set(imagesArray)];
    return uniqueImageUrls;
  }

  async function initiateNewNoteAndState(receipt_id, noteInput, status) {
    await api
      .createReceiptNote(user.id, tokenObject.token, {
        receipt_id: receipt_id,
        note: noteInput,
        status: status,
      })
      .then(() => {
        for (let i = 0; i < props.productData.length; i++) {
          if (props.productData[i].receipt_id === receipt_id) {
            props.productData[i].note = noteInput;
            props.productData[i].status = status;
          }
        }
        props.searchItems();
      });
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function deneme(deneme) {
    console.log(deneme);
  }

  return (
    <div>
      <TableContainer component={Paper}>
        <Table aria-label='simple table'>
          <TableHead>
            <TableRow sx={{ background: '#3694FF' }}>
              <TableCell align='left'>
                Ad Soyad
                <br />
                SKU
                <br />
                Sipariş Numarası
                <br />
                Teslim Tarihi
              </TableCell>

              <TableCell align='left'>Teslimat Adresi</TableCell>
              <TableCell align='left'>Kişiselleştirme</TableCell>
              <TableCell align='left'>Ürün</TableCell>
              <TableCell align='left'>Alıcı Notu</TableCell>
              <TableCell align='left'>Not</TableCell>
              <TableCell align='center' sx={{ displayPrint: 'none' }}>
                Durum
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.productData
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((productData) => {
                return (
                  <TableRow
                    key={productData.receipt_id}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                    }}
                  >
                    <TableCell
                      component='th'
                      scope='row'
                      align='left'
                      size='small'
                      sx={{ maxWidth: 20 }}
                    >
                      {productData.name}
                      <br />
                      {productData.transactions[0].sku}
                      <br />
                      {productData.receipt_id}
                      <br />
                      {productData.new_expected_ship_date}
                      <br />
                      <b>
                        {productData.transactions[0].shipping_upgrade !== null
                          ? productData.transactions[0].shipping_upgrade
                          : ''}
                      </b>
                    </TableCell>

                    <TableCell align='left' size='small' sx={{ maxWidth: 20 }}>
                      {productData.formatted_address}
                    </TableCell>
                    <TableCell size='small' sx={{ maxWidth: 40 }}>
                      {productData.transactions.map((transactions) => {
                        return (
                          <>
                            {transactions.variations.map((productData) => {
                              return (
                                <>
                                  <b>{productData.formatted_name}</b>
                                  {': ' +
                                    productData.formatted_value.replace(
                                      /&quot;/g,
                                      '"'
                                    )}
                                  <br />
                                </>
                              );
                            })}
                            {<b>Quantity: </b>} {transactions.quantity}
                            <br />
                            <br />
                          </>
                        );
                      })}
                    </TableCell>
                    <TableCell align='left' size='small'>
                      {selectImages(productData).map((imageUrl) => {
                        return (
                          <>
                            <img
                              src={imageUrl}
                              alt='Logo'
                              width='100px'
                              onClick={()=>handleOpen(imageUrl)}
                              
                            ></img>
                            <Modal
                              open={open}
                              onClose={handleClose}
                              aria-labelledby='modal-modal-title'
                              aria-describedby='modal-modal-description'
                            >
                              <Box sx={style}>
                                <img src={modalImageUrl} alt='BiggerPicture'></img>
                              </Box>
                            </Modal>
                            
                            <br />
                          </>
                        );
                      })}
                    </TableCell>
                    <TableCell align='left' sx={{ maxWidth: 60 }}>
                      {productData.message_from_buyer}
                    </TableCell>
                    <TableCell align='left' sx={{ width: 150 }}>
                      <TextField
                        defaultValue={productData.note}
                        multiline
                        inputProps={{
                          style: { fontSize: 10 },
                        }}
                        InputLabelProps={{
                          style: { fontSize: 10 },
                        }}
                        onBlur={(event) =>
                          initiateNewNoteAndState(
                            productData.receipt_id,
                            event.target.value,
                            productData.status
                          )
                        }
                      ></TextField>
                    </TableCell>
                    <TableCell align='center' sx={{ displayPrint: 'none' }}>
                      <FormControl variant='filled' size='small'>
                        <InputLabel
                          id='demo-simple-select-label'
                          color='primary'
                        >
                          Sipariş Durumu
                        </InputLabel>
                        <Select
                          color='primary'
                          labelId='demo-simple-select-label'
                          id='demo-simple-select'
                          label='Sipariş Durumu'
                          defaultValue={productData.status}
                          onChange={(event) => {
                            initiateNewNoteAndState(
                              productData.receipt_id,
                              productData.note,
                              event.target.value
                            );
                          }}
                          autoWidth
                        >
                          <MenuItem value={'Paid'}>Yeni Sipariş</MenuItem>
                          <MenuItem value={'Supply'}>Tedarikte</MenuItem>
                          <MenuItem value={'Open'}>Gönderildi</MenuItem>
                          <MenuItem value={'Completed'}>Teslim Edildi</MenuItem>
                          <MenuItem value={'Payment Processing'}>
                            Tekrar Gönderi
                          </MenuItem>
                          <MenuItem value={'Canceled'}>İptal Edildi</MenuItem>
                        </Select>
                      </FormControl>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        component='div'
        count={props.productData === undefined ? 0 : props.productData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{ displayPrint: 'none' }}
      />
    </div>
  );
};

export default ProductDataTable;
