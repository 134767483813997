import {createSlice} from "@reduxjs/toolkit"

const authSlice = createSlice({
  name: 'auth',
  initialState: {email: "", password: "", user: "", accessToken: "", refreshToken: ""},
  reducers: {
    setLoginCredentials: (state, action) => {
      const {email, password} = action.payload
      state.email = email
      state.password = password
    },
    setLogOutCredentials: (state, action) => {
      const {accessToken, refreshToken} = action.payload
      state.accessToken = null
      state.accessToken = null
    },
    setToken: (state, action) => {
      const {accessToken, refreshToken} = action.payload
      state.accessToken = accessToken
      state.refreshToken = refreshToken
    },
    setUser: (state, action) => {
      const {user} = action.payload
      state.user = user
    },
    setForgotPasswordCredentials :(state, action)=>{
      const {email} = action.payload
      state.email = email
    }
  },
})


export const {setLoginCredentials, setLogOutCredentials, setToken, setUser, setForgotPasswordCredentials} = authSlice.actions

export const authReducer = authSlice.reducer

export const selectCurrentUser = (state) => state.auth.user
export const selectAccessToken = (state) => state.auth.accessToken
