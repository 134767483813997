import { useEffect, useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import api from '../../middleware/backendApi.ts';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import IconButton from '@mui/material/IconButton';
import { Edit } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import TableContainer from '@mui/material/TableContainer';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TablePagination from '@mui/material/TablePagination';
import {
  CssBaseline,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import NavBar from '../NavBar/NavBar';
import { useSelector } from 'react-redux';
import { selectAccessToken } from '../../api/authSlice';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const AdminPage = () => {
  //UseState
  const [userList, setUserlist] = useState([{}]);
  const [filteredResults, setFilteredResults] = useState([]);
  const [selectedUser, setSelectedUser] = useState({});
  const [open, setOpen] = useState(false);
  const [updateOpen, setUpdateOpen] = useState(false);
  const [newUser, setNewUser] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const token = useSelector(selectAccessToken);

  async function getUsers() {
    await api
      .getUsers(10, 1, token.token)
      .then((response) => {
        setUserlist(response);
      })
      .catch();
  }

  //Pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  function handleOpen() {
    setOpen(true);
  }

  function handleClose() {
    setNewUser({});
    setOpen(false);
    getUsers();
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    api
      .createUser(
        newUser.name,
        newUser.email,
        newUser.role,
        newUser.shopId,
        token.token
      )
      .then(getUsers)
      .catch();

    handleClose();
  };

  const handleUpdateSubmit = (event) => {
    event.preventDefault();
    api
      .updateUser(
        selectedUser.id,
        selectedUser.name,
        selectedUser.email,
        selectedUser.shopId,
        token.token
      )
      .then()
      .catch();
    handleUpdateClose();
  };

  const handleDelete = (id) => {
    api
      .deleteUser(id, token.token)
      .then((response) => {
        
        getUsers();
      })
      .catch();
  };

  const handleUpdateOpen = (row) => {
    setSelectedUser(row);
    setUpdateOpen(true);
  };
  const handleUpdateClose = () => {
    setSelectedUser({});
    setUpdateOpen(false);
    getUsers();
  };

  const handleUpdateChange = (event) => {
    const { name, value } = event.target;
    setSelectedUser((prevState) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };
  const handleChange = (event) => {
    const { name, value } = event.target;

    setNewUser((prevState) => {
      return {
        ...prevState,

        [name]: value,
      };
    });
  };
  useEffect(() => {
    getUsers();
  }, []);

  const searchItems = (searchValue) => {
    const textBoxSearchValue = searchValue ? searchValue : '';

    if (textBoxSearchValue !== '') {
      const filteredData = userList.filter((item) => {
        return Object.values(item)
          .join('')
          .toLowerCase()
          .includes(textBoxSearchValue.toLowerCase());
      });
      setFilteredResults(filteredData);
    } else {
      setFilteredResults(userList);
    }
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <NavBar name='Admin' list='Kullanıcı Listesi' />

      <Stack
        direction='row'
        spacing={50}
        alignItems='center'
        justifyContent='space-between'
      >
        <Box
          component='form'
          sx={{
            '& > :not(style)': { m: 1, width: '25ch' },
          }}
          noValidate
          autoComplete='off'
        >
          <TextField
            id='searchbar'
            label='Arama'
            variant='outlined'
            onChange={(e) => searchItems(e.target.value)}
          />
        </Box>

        <Button onClick={handleOpen} variant='contained' color='success'>
          Oluştur
        </Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Box sx={modalStyle} component={'form'} onSubmit={handleSubmit}>
            <Stack alignItems='row' spacing={2}>
              <text b color='inherit' hideIn='xs'>
                Yeni Kullanıcı Oluştur
              </text>
              <TextField
                onChange={handleChange}
                id='name'
                label='Name'
                name='name'
                variant='outlined'
              />
              <TextField
                onChange={handleChange}
                id='email'
                label='Email'
                name='email'
                variant='outlined'
              />
              <FormControl fullWidth>
                <InputLabel id='roleLabel'>Role</InputLabel>
                <Select
                  labelId='roleLabel'
                  id='role'
                  label='Role'
                  name='role'
                  onChange={handleChange}
                >
                  <MenuItem id='role' value={'admin'}>
                    Admin
                  </MenuItem>
                  <MenuItem id='role' value={'user'}>
                    User
                  </MenuItem>
                </Select>
              </FormControl>
              <TextField
                onChange={handleChange}
                id='shopId'
                label='Shop ID'
                name='shopId'
                variant='outlined'
              />
              <Button variant='contained' color='success' type={'submit'}>
                Oluştur
              </Button>
            </Stack>
          </Box>
        </Modal>
        <Modal
          open={updateOpen}
          onClose={handleUpdateClose}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Box sx={modalStyle} component={'form'} onSubmit={handleUpdateSubmit}>
            <Stack alignItems='row' spacing={2}>
              <text b color='inherit' hideIn='xs'>
                Kullanıcı Güncelle
              </text>
              <TextField
                onChange={handleUpdateChange}
                defaultValue={selectedUser.name}
                id='name'
                label='Name'
                name='name'
                variant='outlined'
              />
              <TextField
                onChange={handleUpdateChange}
                defaultValue={selectedUser.email}
                id='email'
                label='Email'
                name='email'
                variant='outlined'
              />
              <FormControl fullWidth>
                <InputLabel id='roleLabel'>Role</InputLabel>
                <Select
                  labelId='roleLabel'
                  defaultValue={selectedUser.role}
                  id='role'
                  label='Role'
                  name='role'
                  onChange={handleUpdateChange}
                >
                  <MenuItem id='role' value={'admin'}>
                    Admin
                  </MenuItem>
                  <MenuItem id='role' value={'user'}>
                    User
                  </MenuItem>
                </Select>
              </FormControl>
              <TextField
                onChange={handleUpdateChange}
                defaultValue={selectedUser.shopId}
                id='shopId'
                label='Shop ID'
                name='shopId'
                variant='outlined'
              />
              <Button variant='contained' color='success' type={'submit'}>
                Düzenle
              </Button>
            </Stack>
          </Box>
        </Modal>
      </Stack>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow key={'topRow'} sx={{ background: '#3694FF' }}>
              <TableCell align='left'>
                ID
              </TableCell>
              <TableCell align='center'>
                UserName
              </TableCell>
              <TableCell align='center'>
                Email
              </TableCell>
              <TableCell align='center'>
                ShopID
              </TableCell>
              <TableCell align='center'>
                E-Mail Verified
              </TableCell>
              <TableCell align='right'>
                Edit/Delete
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredResults.length > 1
              ? filteredResults
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <TableRow
                      key={row.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component='th' scope='row' align='left'>
                        {row.id}
                      </TableCell>
                      <TableCell align='center'>{row.name}</TableCell>
                      <TableCell align='center'>{row.email}</TableCell>
                      <TableCell align='center'>{row.shopId}</TableCell>
                      <TableCell align='center'>
                        <FormControlLabel
                          control={
                            <Checkbox
                              defaultChecked={row.isEmailVerified}
                              disabled
                            />
                          }
                        />
                      </TableCell>
                      <TableCell align='right'>
                        <Button onClick={() => handleUpdateOpen(row)}>
                          <Edit />
                        </Button>
                        <IconButton
                          onClick={() => handleDelete(row.id)}
                          aria-label='delete'
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
              : userList
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => (
                    <TableRow
                      key={row.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell
                        key={row.id}
                        component='th'
                        scope='row'
                        align='left'
                      >
                        {row.id}
                      </TableCell>
                      <TableCell key={row.name} align='center'>
                        {row.name}
                      </TableCell>
                      <TableCell key={row.email} align='center'>
                        {row.email}
                      </TableCell>
                      <TableCell key={row.shopId} align='center'>
                        {row.shopId}
                      </TableCell>
                      <TableCell key={row.isEmailVerified} align='center'>
                        <FormControlLabel
                          control={
                            <Checkbox
                              defaultChecked={row.isEmailVerified}
                              disabled
                            />
                          }
                        />
                      </TableCell>
                      <TableCell key={'buttons'} align='right'>
                        <Button onClick={() => handleUpdateOpen(row)}>
                          <Edit />
                        </Button>
                        <IconButton
                          onClick={() => handleDelete(row.id)}
                          aria-label='delete'
                        >
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component='div'
        count={userList.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </ThemeProvider>
  );
};

export default AdminPage;
