import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { setLoginCredentials, setLogOutCredentials } from './authSlice';

const baseQuery = fetchBaseQuery({
  baseUrl:
    process.env.NODE_ENV === 'development'
      ? process.env.REACT_APP_INTERNAL_API_DEVELOPMENT
      : process.env.REACT_APP_INTERNAL_API_PRODUCTION,
  credentials: 'same-origin',
  mode: 'cors',
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth.tokens;
    if (token) {
      headers.set('Accept', '*');
      headers.set('Access-Control-Allow-Origin', '*');
      headers.set('authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (result?.error?.originalStatus === 401) {
    // send refresh token to get new access token
    const refreshResult = await baseQuery(
      '/auth/refresh-tokens',
      api,
      extraOptions
    );
    if (refreshResult?.data) {
      const user = api.getState().auth.user;
      // store the new token
      api.dispatch(setLoginCredentials({ ...refreshResult.data, user }));
      // retry the original query with new access token
      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(setLogOutCredentials());
    }
  }

  return result;
};

export const apiSlice = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({}),
});
