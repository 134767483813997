import { ThemeProvider } from '@emotion/react';
import {
  Alert,
  Box,
  Button,
  createTheme,
  CssBaseline,
  TextField,
  Typography,
} from '@mui/material';
import { IconButton, InputAdornment } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import api from '../../middleware/backendApi.ts';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const CreateNewPasswordPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showNewPasswordAgain, setShowNewPasswordAgain] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [newPasswordAgain, setNewPasswordAgain] = useState('');
  const [isPasswordsSame, setIsPasswordsSame] = useState(false);
  const [errMsg, setErrMsg] = useState('');

  const resetPasswordToken = searchParams.get('token');

  useEffect(() => {
    if (resetPasswordToken === null || !resetPasswordToken) {
      navigate('/');
    }
  }, []);

  const handleClickShowPassword = () => setShowNewPassword((show) => !show);
  const handleClickShowPasswordAgain = () =>
    setShowNewPasswordAgain((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleNewPwInput = (e) => setNewPassword(e.target.value);
  const handleNewPwInputAgain = (e) => setNewPasswordAgain(e.target.value);

  useEffect(() => {
    if (newPassword === '' || newPasswordAgain === '') {
      setErrMsg('Lütfen yukarıdaki alanları doldurun');
      return;
    }
    if (newPassword !== newPasswordAgain) {
      setIsPasswordsSame(false);
      setErrMsg('Şifreler Uyuşmuyor');
    } else {
      setIsPasswordsSame(true);
      setErrMsg('Şifreler Uyuşuyor');
    }
  }, [newPasswordAgain]);

  async function SubmitNewPassword() {
    await api
      .resetPassword(resetPasswordToken, newPassword)
      .then((res) => {
        if(res.status === 204){
          navigate('/');
        }
      })
      .catch((err) => setErrMsg(err.response.data.message));
  }

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline>
        <Box
          sx={{
            marginTop: 15,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography>Lütfen yeni şifrenizi giriniz</Typography>
          <Box component='form' noValidate sx={{ mt: 1 }}>
            <TextField
              margin='normal'
              value={newPassword}
              onChange={handleNewPwInput}
              required
              fullWidth
              name='newpassword'
              label='Yeni Şifre'
              type={showNewPassword ? 'text' : 'password'}
              id='newpassword'
              autoComplete='current-password'
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge='end'
                    >
                      {showNewPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              margin='normal'
              value={newPasswordAgain}
              onChange={handleNewPwInputAgain}
              required
              fullWidth
              name='newpasswordagain'
              label='Yeni Şifre(Tekrar)'
              type={showNewPasswordAgain ? 'text' : 'password'}
              id='newpasswordagain'
              autoComplete='current-password'
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={handleClickShowPasswordAgain}
                      onMouseDown={handleMouseDownPassword}
                      edge='end'
                    >
                      {showNewPasswordAgain ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <Button
              fullWidth
              variant='contained'
              onClick={SubmitNewPassword}
              disabled={!isPasswordsSame}
              sx={{ mt: 3, mb: 2 }}
            >
              Gönder
            </Button>
            {errMsg !== '' && !isPasswordsSame ? (
              <Alert variant='outlined' severity='error'>
                {errMsg}
              </Alert>
            ) : (
              <Alert variant='outlined' severity='success'>
                {errMsg}
              </Alert>
            )}
          </Box>
        </Box>
      </CssBaseline>
    </ThemeProvider>
  );
};

export default CreateNewPasswordPage;
