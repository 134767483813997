import { useEffect, useState } from 'react';
import NavBar from '../NavBar/NavBar';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import etsyApi from '../../middleware/etsyApiClient.ts';
import { Alert, Button, CssBaseline } from '@mui/material';
import { useSelector } from 'react-redux/es';
import { selectAccessToken, selectCurrentUser } from '../../api/authSlice';
import api from '../../middleware/backendApi.ts';
import { HashLoader } from 'react-spinners';
import ProductDataTable from '../ProductDataTable/ProductDataTable';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const MainPage = () => {
  const [filteredResults, setFilteredResults] = useState([]);
  const [productDataArray, setproductDataArray] = useState();
  const [shopListing, setShopListing] = useState([]);
  const [textboxSearchValue, setTextboxSearchValue] = useState('');
  const [dropdownSearchValue, setDropdownSearchValue] = useState('');
  const tokenObject = useSelector(selectAccessToken);
  const user = useSelector(selectCurrentUser);

  useEffect(() => {
    getData();
    getListingsByShop();
  }, []);
  useEffect(()=>{console.log(shopListing)},[shopListing])

  useEffect(() => {
    if (productDataArray !== undefined && productDataArray?.length !== 0) {
      for (let i = 0; i < productDataArray.length; i++) {
        productDataArray[i].new_expected_ship_date = Intl.DateTimeFormat()
          .format(productDataArray[i].transactions[0].expected_ship_date * 1000)
          .toString();
      }
    }
  }, [productDataArray]);

  useEffect(() => console.log(productDataArray), [productDataArray]);

  async function getData() {
    setproductDataArray(undefined);
    return await etsyApi
      .getShopReceipts(user.id, tokenObject.token)
      .then((res) => {
        if (res.results) {
          setproductDataArray(res.results);
        } else {
          setproductDataArray([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function getAuthCode() {
    await api
      .requestAuthCode(user.id, tokenObject.token)
      .then((res) => window.open(res, '_blank', 'noreferrer'))
      .catch();
  }

  const searchTableItems = () => {
    if (textboxSearchValue !== '' || dropdownSearchValue !== '') {
      const filteredData = productDataArray.filter((item) => {
        return (
          Object.values(item)
            .join('')
            .toLowerCase()
            .includes(textboxSearchValue.toLowerCase().toString()) &&
          Object.values(item)
            .join('')
            .toLowerCase()
            .includes(dropdownSearchValue.toLowerCase())
        );
      });
      setFilteredResults(filteredData);
    } else {
      setFilteredResults(productDataArray);
    }
  };

  useEffect(() => {
    searchTableItems();
  }, [textboxSearchValue, dropdownSearchValue]);

  async function getEtsyTokenMissing() {
    api
      .getEtsyAccessToken(user.id, tokenObject.token)
      .then((res) => console.log(res))
      .catch((err) => err);
  }
  async function getListingsByShop() {
    return await etsyApi
      .getListingsByShop(user.id, tokenObject.token)
      .then((res) => {
        setShopListing(res.data.results);
      })
      .catch((error) => console.log(error));
  }

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <div>
        <div>
          <NavBar name={user?.name} list={'Ürün Listesi'} />
        </div>
        <div>
          {productDataArray !== undefined && productDataArray?.length !== 0 ? (
            <Box
              component='form'
              sx={{
                '& > :not(style)': { m: 1, width: '25ch' },
                displayPrint: 'none',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
              noValidate
              autoComplete='off'
            >
              <TextField
                id='searchbar'
                label='Arama'
                variant='outlined'
                sx={{ displayPrint: 'none' }}
                onChange={(e) => setTextboxSearchValue(e.target.value)}
              />
              <Button
                variant='contained'
                onClick={getData}
                sx={{ displayPrint: 'none', alignSelf: 'center' }}
              >
                Siparişleri Yenile
              </Button>
              <FormControl fullWidth>
                <InputLabel id='demo-simple-select-label'>
                  Sipariş Durumu
                </InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  label='Age'
                  onChange={(e) => setDropdownSearchValue(e.target.value)}
                >
                  <MenuItem value={''}>Hepsi</MenuItem>
                  <MenuItem value={'Paid'}>Yeni Sipariş</MenuItem>
                  <MenuItem value={'Supply'}>Tedarikte</MenuItem>
                  <MenuItem value={'Open'}>Gönderildi</MenuItem>
                  <MenuItem value={'Completed'}>Teslim Edildi</MenuItem>
                  <MenuItem value={'Payment Processing'}>
                    Tekrar Gönderi
                  </MenuItem>
                  <MenuItem value={'Canceled'}>İptal Edildi</MenuItem>
                </Select>
              </FormControl>
            </Box>
          ) : null}
        </div>
        {productDataArray !== undefined && productDataArray?.length !== 0 ? (
          <div>
            {filteredResults?.length >= 1 ? (
              <ProductDataTable
                productData={filteredResults}
                shopListing={shopListing}
                name='filtered'
                searchItems={searchTableItems}
              />
            ) : (
              <ProductDataTable
                productData={productDataArray}
                shopListing={shopListing}
                name='Product'
                searchItems={searchTableItems}
              />
            )}
          </div>
        ) : productDataArray === undefined ? (
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <HashLoader color='#368ed6' />
          </Box>
        ) : (
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Alert variant='outlined' severity='error'>
              Herhangi bir ürüne rastlanmadı.
            </Alert>
            <br></br>
            {user.isEtsyAuthorized === false ? (
              <>
                <Alert variant='outlined' severity='info'>
                  Etsy yetkilendirmeniz bulunmamaktadır. Lütfen aşağıdaki
                  butondan yetkilendirme yapınız.
                </Alert>
                <br></br>
                <Button
                  variant='contained'
                  color='info'
                  onClick={getAuthCode}
                  sx={{ minHeight: 40 }}
                >
                  Yetkilendir
                </Button>
              </>
            ) : user.etsyAccessToken === null || user.etsyAccessToken === '' ? (
              <>
                <Alert variant='outlined' severity='info'>
                  Etsy Tokenleriniz alınırken bir sorun oluştu. Lütfen aşağıdaki
                  butondan tekrar deneyiniz.
                </Alert>
                <br></br>
                <Button
                  variant='contained'
                  color='info'
                  onClick={getEtsyTokenMissing}
                  sx={{ minHeight: 40 }}
                >
                  Token Yenile
                </Button>
              </>
            ) : (
              <div></div>
            )}
          </Box>
        )}
      </div>
    </ThemeProvider>
  );
};

export default MainPage;
