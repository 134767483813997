// @ts-ignore
import {createApi} from "./apiClient.ts";

export default class api {
  static internalApi = process.env.NODE_ENV === "development" ? process.env.REACT_APP_INTERNAL_API_DEVELOPMENT : process.env.REACT_APP_INTERNAL_API_PRODUCTION;
  static api = createApi(this.internalApi);

  static login(email, password) {
    return this.api.post('/auth/login',
      {
        "email": email,
        "password": password,
      },
      {
        headers: {
          "Content-Type": "application/json",
        }
      })
      .then(res => res.data)
      .catch(error => error)
  }

  static logout(refreshToken, accessToken) {
    return this.api.post('/auth/logout',
      {
        "refreshToken": refreshToken,
      },
      {
        headers: {
          Authorization: "Bearer " + accessToken,
          "Content-Type": "application/json",
        },
      })
      .then(res => res.data)
      .catch(error => error)
  }

  static refreshToken(refreshToken, accessToken) {
    return this.api.post('/auth/refresh-tokens',
      {
        headers: {
          Authorization: "Bearer " + accessToken,
          "Content-Type": "application/json",
        },
        data: {
          "refreshToken": refreshToken,
        }
      }, {})
      .then(res => res.data)
      .catch(error => error)
  }

  static forgotPassword(email) {
    return this.api.post('/auth/forgot-password',
      {
        "email": email,
      }
    )
      .then(res => res.data)
      .catch(error => error)
  }

  static resetPassword(token, password) {
    return this.api.post('/auth/reset-password?token=' + token,
      {"password": password})
      .then(res => res)
      .catch(error => error)
  }

  static sendVerificationEmail() {
    return this.api.post('/auth/send-verification-email')
      .then(res => res.data)
      .catch(error => error)
  }

  static verifyEmail(token) {
    return this.api.post('auth/verify-email?token=f' + token)
      .then(res => res.data)
      .catch(error => error)
  }

  static createUser(name, email, role, shopId, accessToken) {
    return this.api.post('/users', {
      "name": name,
      "email": email,
      "role": role,
      "shopId": shopId
    }, {
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
      }
    })
      .then(res => res.data)
      .catch(error => error)
  }

  static getUsers(limit, page, accessToken) {
    return this.api.get('/users?limit=' + limit + '&page=' + page, {
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
      }
    })
      .then(res => res.data)
      .catch(error => error)
  }

  static getUser(userId, accessToken) {
    return this.api.get('/users/' + userId, {
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
      }
    })
      .then(res => res.data)
      .catch(error => error)
  }

  static updateUser(userId, name, email, shopId, accessToken) {
    return this.api.patch('/users/' + userId,
      {
        "name": name,
        "email": email,
        "shopId": shopId
      },
      {
        headers: {
          Authorization: "Bearer " + accessToken,
          "Content-Type": "application/json",
        }
      })
      .then(res => res.data)
      .catch(error => error)
  }

  static deleteUser(userId, accessToken) {
    return this.api.delete('/users/' + userId, {
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
      }
    })
      .then(res => res.data)
      .catch(error => error)
  }

  static requestAuthCode(userId, accessToken) {
    return this.api.get('/etsy/requestAuthCode/' + userId, {
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
      }
    })
      .then(res => res.data)
      .catch(error => error)
  }

  static getEtsyAccessToken(userId, accessToken) {
    return this.api.get('/etsy/accessToken/' + userId, {
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
      }
    })
  }

  static refreshEtsyTokens(userId, accessToken){
    return this.api.get('/etsy/refreshTokens/'+userId, {
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
      }
    })
      .then(res => res.data)
      .catch(error => error)
  }

  static createReceiptNote(userId, accessToken, data){
    return this.api.post('/notes/createNote/' + userId, {
      receipt_id : data.receipt_id,
      note: data.note,
      status: data.status
    }, {
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
      }
    })
    .then(res => res.data)
    .catch(error => error)
  }

  static updateReceiptNote(accessToken, data){
    return this.api.patch('/notes/updateNote', {
      receipt_id : data.receipt_id,
      note: data.note
    }, {
      headers: {
        Authorization: "Bearer " + accessToken,
        "Content-Type": "application/json",
      }
    })
    .then(res => res.data)
    .catch(error => error)
  }

  static deleteReceipNote(accessToken, receipt_id){
    return this.api.delete('/notes/deleteNote/' + receipt_id, {
      headers: {
        Authorization: "Bearer " + accessToken,
      }
    })
    .then(res => res.data)
    .catch(error => error)
  }

  static getReceiptNotes(accessToken, userId){
    return this.api.get('/notes/getNotes/' + userId, {
      headers: {
        Authorization: "Bearer " + accessToken,
      }
    })
    .then(res => res.data)
    .catch(error => error)
  }
  
  static getReceiptNote(accessToken, receipt_id){
    return this.api.get('/notes/getNote/' + receipt_id, {
      headers: {
        Authorization: "Bearer " + accessToken,
      }
    })
    .then(res => res.data)
    .catch(error => error)
  }
}
