import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import { Button, Stack } from "@mui/material";
import api from "../../middleware/backendApi.ts";
import { useNavigate } from "react-router-dom";

function NavBar(props) {
  const name = props.name;
  const whatList = props.list;
  const navigate = useNavigate();

  const token = localStorage.getItem("access");

  async function logout(){
    api
      .logout(token)
      .then((response) => {navigate("/")})
      .catch();
  };

  return (
    <AppBar position="relative" sx={{displayPrint:'none'}}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography
          variant="h6"
          noWrap
          component="a"
          sx={{
            ml: 3,
            mt: 3,
            mb: 3,
            mr: -30,
            display: { xs: "none", md: "flex" },
            fontFamily: "monospace",
            fontWeight: 700,
            letterSpacing: ".3rem",
            color: "inherit",
            textDecoration: "none",
          }}
        >
          SİPARİŞ TAKİP ({name})
        </Typography>

        <Button variant="text" color="info">
          {whatList}
        </Button>

        <Button variant="contained" color="error" onClick={logout}>
          Çıkış Yap
        </Button>
      </Stack>
    </AppBar>
  );
}
export default NavBar;
