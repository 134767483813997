import MainPage from "./Components/MainPage/MainPage";
import LoginPage from "./Components/LoginPage/LoginPage";
import {Navigate, Outlet, Route, Routes} from "react-router-dom";
import AdminPage from "./Components/AdminPage/AdminPage";
import {useSelector} from "react-redux";
import {selectAccessToken, selectCurrentUser} from "./api/authSlice";
import CreateNewPasswordPage from "./Components/CreateNewPasswordPage/CreateNewPasswordPage";
import ForgotPasswordPage from "./Components/ForgotPasswordPage/ForgotPasswordPage"
import CallbackPage from "./Components/CallbackPage/CallbackPage";

function App() {
 const  token = useSelector(selectAccessToken)
 const  user = useSelector(selectCurrentUser) || null
  return (
    <div className="app">
      <Routes>
          {/* public routes */}
          <Route path="*" element={<Navigate to="/login" />}/>
          <Route path="/login" element={<LoginPage/>}/>
          <Route path="AdminPage" element={user && user.role ==="admin" ? <AdminPage/>:<Navigate to={"/"}/>}/>
          <Route path="MainPage" element={user && user.role ==="user" ? <MainPage/>:<Navigate to={"/"}/>}/>
          <Route path="/forgot-password" element={<ForgotPasswordPage/>}/>
          <Route path="/reset-password" element={<CreateNewPasswordPage/>}/>
          <Route path="/redirect" element={<CallbackPage/>}/>

      </Routes>
    </div>
  );
}

export default App;
