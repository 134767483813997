// @ts-ignore
import { userId } from '../../../src/validations/externalApi.validation';
import { createApi } from './apiClient.ts';

export default class etsyApi {
  static etsyApiUrl =
    process.env.NODE_ENV === 'development'
      ? process.env.REACT_APP_ESTY_API_URL_DEVELOPMENT
      : process.env.REACT_APP_ESTY_API_URL_PRODUCTION;
  static etsyApi = createApi(this.etsyApiUrl);
  static estyApiKey = process.env.REACT_APP_ETSY_API_KEYSTRING;

  static tokenScopes(accessToken) {
    return this.etsyApi.post('v3/application/scopes', {
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    });
  }

  static getShopReceipt(userId, receipt_id, accessToken) {
    return this.etsyApi
      .get('getShopReceipt/' + userId + '/receipts/' + receipt_id, {
        headers: {
          Authorization: 'Bearer ' + accessToken,
        },
      })
      .then((res) => res.data)
      .catch((error) => error);
  }

  static updateShopReceipt(receipt_id, userId, data, accessToken) {
    return this.etsyApi.put(
      'updateShopReceipt/' + receipt_id + '/' + userId,{
        data:data
      },
      {
        headers: {
          Authorization: 'Bearer ' + accessToken,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        data: data,
      }
    );
  }

  static getShopReceipts(userId, accessToken) {
    return this.etsyApi
      .get('getShopReceipts/' + userId +'?limit=100&offset=0', {
        headers: {
          Authorization: 'Bearer ' + accessToken,
        },
        
      })
      .then((res) => res.data)
      .catch((error) => error);
  }

  // static createReceiptShipment(shopId, receipt_id, etsyToken, data) {
  //   return this.etsyApi
  //     .post(
  //       'v3/application/shops/' +
  //         shopId +
  //         '/receipts/' +
  //         receipt_id +
  //         '/tracking',
  //       {
  //         headers: {
  //           'x-api-key': this.estyApiKey,
  //           Authorization: 'Bearer ' + etsyToken,
  //           'Content-Type': 'application/x-www-form-urlencoded',
  //         },
  //         data: data,
  //       }
  //     )
  //     .then((res) => res.data)
  //     .catch((error) => error);
  // }

  // static getShopReceiptTransactionsByListing(shopId, listing_id, etsyToken) {
  //   return this.etsyApi
  //     .get(
  //       'v3/application/shops/' +
  //         shopId +
  //         '/listings/' +
  //         listing_id +
  //         '/transactions',
  //       {
  //         headers: {
  //           'x-api-key': this.estyApiKey,
  //           Authorization: 'Bearer ' + etsyToken,
  //         },
  //       }
  //     )
  //     .then((res) => res.data)
  //     .catch((error) => error);
  // }

  // static getShopReceiptTransactionsByReceipt(shopId, receipt_id, etsyToken) {
  //   return this.etsyApi
  //     .get(
  //       'v3/application/shops/' +
  //         shopId +
  //         '/receipts/' +
  //         receipt_id +
  //         '/transactions',
  //       {
  //         headers: {
  //           'x-api-key': this.estyApiKey,
  //           Authorization: 'Bearer ' + etsyToken,
  //         },
  //       }
  //     )
  //     .then((res) => res.data)
  //     .catch((error) => error);
  // }

  // static getShopReceiptTransaction(shopId, transaction_id, etsyToken) {
  //   return this.etsyApi
  //     .get(
  //       'v3/application/shops/' + shopId + '/transactions/' + transaction_id,
  //       {
  //         headers: {
  //           'x-api-key': this.estyApiKey,
  //           Authorization: 'Bearer ' + etsyToken,
  //         },
  //       }
  //     )
  //     .then((res) => res.data)
  //     .catch((error) => error);
  // }

  // static getShopReceiptTransactionsByShop(shopId, etsyToken) {
  //   return this.etsyApi
  //     .get('v3/application/shops/' + shopId + '/transactions', {
  //       headers: {
  //         'x-api-key': this.estyApiKey,
  //         Authorization: 'Bearer ' + etsyToken,
  //       },
  //     })
  //     .then((res) => res.data)
  //     .catch((error) => error);
  // }

  static getListingImage(listing_id, listing_image_id, userId, accessToken) {
    return this.etsyApi
      .get('getListingImage/' + listing_id + '/images/' + listing_image_id+ '/'+userId,{ 
        headers: { 
          Authorization: 'Bearer ' + accessToken,
        }, 
      })
      .then((res) => res)
      .catch((error) => error);
  }
  static getListingImages(listing_id, userId, accessToken) {
    return this.etsyApi
      .get('getListingImage/' + listing_id + '/images/' +userId,{ 
        headers: { 
          Authorization: 'Bearer ' + accessToken,
        }, 
      })
      .then((res) => res)
      .catch((error) => error);
  }

  static getListingsByShop(userId, accessToken){
    return this.etsyApi
    .get('getListingsByShop/'+userId, {
      headers: {
        Authorization: 'Bearer ' + accessToken,
      }
    })
    .then((res)=> res)
    .catch((error)=> error);
  }



}
